import React, { Component } from 'react';
import Select from 'react-select';
import { isLoggedIn, fetchSignedMessage, getSessionValue, postAttachment, businessIdHeader, httpOKsuccess, postMethod, cssFormInputFile, cssFormInputLabel, fileFieldType, textField, cssFormInput, cssFormInputText, cssError, cssFormInputButton, cssFormInputDropdown, cssFormCheckBox, userIdHeader, userRoleHeader, roleAdministrator, checkBox, roleAgent, roleFranchisor, roleMerchant, numberField, buttonType, businessesHeader, uploadFileToBucket, emailRegularExpression, httpConflict, emailAddressLength, standardTextLength, cognitoAttributeLength } from './Library'
import DualBallSpinner from '../img/dual-ball-1s-100px.gif';
import ReactDOM from 'react-dom';
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext'
import { NavMenu } from '../components/NavMenu'
import { validate } from 'C:/Users/vince/AppData/Local/Microsoft/TypeScript/5.4/node_modules/@babel/types/lib/index';

export class CountryOption {
	constructor(key, text) {
		this.label = text; 
		this.value = key;
	}
}



export class Business extends Component {
	constructor(props) {
		super(props)
		this.state = {
			businessName: '',
			addressLine1: '',
			addressLine2: '',
			town: '',
			county: '',
			postcode: '',
			countryId: null,
			phoneNumber: '',
			parentBusinessId: '',
			icon: null,
			notificationIcon: null,
			partnerBusinessID: '',
			notificationIconPath: '',
			countrys: [],
			franchiseBusiness: false,
			users: [{ firstName: '', lastName: '', emailAddress: '', franchisor: false, role: roleMerchant, firstNameError: '', lastNameError:'', emailAddressError: '' }],
			loading: true,
			sumbitButtonDisabled: false,
			businessNameError: '',
			addressLine1Error: '',
			townError: '',
			postcodeError: '',
			countryIdError: '',
			validated: true
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		this.getCountryData();
	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleFileChange = (event) => {
		const name = event.target.name;
		this.setState({
			[name]: event.target.files[0]
		})
	}

	handleDropDownChange = (e, data) => {
		const name = data.name;
		this.setState({
			[name]: e.value
		})
	}

	handleSubmit = async (event) => {

		event.preventDefault();
		let validated = true;

		validated = this.handleValidation();

		for (let i = 0; i < this.state.users.length; i++) {
			if (!this.handleUserValidation(this.state.users[i])) {
				validated = false;
			}
		}
		
		if(validated) {
			var responseLabel = document.getElementById("businessSaveResponse");

			this.setState({ loading: true, sumbitButtonDisabled: true });
			var business = {
				businessName: this.state.businessName,
				addressLine1: this.state.addressLine1,
				addressLine2: this.state.addressLine2,
				town: this.state.town,
				county: this.state.county,
				postcode: this.state.postcode,
				countryId: this.state.countryId,
				phoneNumber: this.state.phoneNumber,
				partnerBusinessID: this.state.partnerBusinessID
			}
			if (this.state.icon != null) {
				this.state.iconPath = uploadFileToBucket(this.state.icon, process.env.REACT_APP_BUSINESS_ICONS_BUCKET);
				business.iconPath = 'https://' + process.env.REACT_APP_BUSINESS_ICONS_BUCKET + '.s3.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + this.state.iconPath;
			}

			if (this.state.notificationIcon != null) {
				this.state.notificationIconPath = uploadFileToBucket(this.state.notificationIcon, process.env.REACT_APP_BUSINESS_ICONS_BUCKET);
				business.notificationIconPath = 'https://' + process.env.REACT_APP_BUSINESS_ICONS_BUCKET + '.s3.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + this.state.notificationIconPath;
			}


			const userRole = this.state.franchiseBusiness ? roleFranchisor : roleMerchant

			var agentUser = {
				identityId: JSON.parse(sessionStorage.getItem(userIdHeader))
			};

			var businessAndUsers = {
				business: business,
				users: [agentUser]
			}

			for (let i = 0; i < this.state.users.length; i++) {
				const user = this.state.users[i];
				user.role = user.franchisor ? roleFranchisor : roleMerchant;
				businessAndUsers.users.push(user);
			}

			let apiMethod = this.state.franchiseBusiness ? 'business/franchisor' : 'business';

			try {

				const response = await fetchSignedMessage(JSON.stringify(businessAndUsers), apiMethod, postMethod);

				if (response.status == httpOKsuccess) {
					const data = await response.json();
					business.businessId = data;
					let currentBusinesses = JSON.parse(sessionStorage.getItem(businessesHeader));
					currentBusinesses.push(business);
					sessionStorage.setItem(businessesHeader, JSON.stringify(currentBusinesses));
					ReactDOM.render(<label className={cssFormInputLabel}>Business saved succesfully.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				} else if (response.status == httpConflict) {
					ReactDOM.render(<label className={cssFormInputLabel}>Email address is already in use.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				} else {
					ReactDOM.render(<label className={cssFormInputLabel}>Business saving failed. Please try again later.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				}
			}
			catch (error) {
				this.setState({ loading: false, sumbitButtonDisabled: false });
				ReactDOM.render(<label className={cssFormInputLabel}>Business saving failed. Please try again later.</label>, responseLabel)
			}
		}
	}

	handleAddUser = (event) => {
		this.setState({
			users: this.state.users.concat(
				[{ firstName: '', lastName: '', emailAddress: '', franchisor: false, role: roleMerchant }]
			)
		})
	}

	handleUserChange = (elementIndex) => (event) => {
		let users = this.state.users.map((item, i) => {
			if (elementIndex !== i) return item
			return { ...item, [event.target.name]: event.target.type === numberField ? Number(event.target.value) : event.target.value }
		});

		this.setState({ users });
	}

	handleRemoveUser = (elementIndex) => (event) => {
		this.setState({
			users: this.state.users.filter((item, i) => {
				return elementIndex != i
			})
		})
	}


	handleUserValidation(item) {
		let validated = true;

		if (item.firstName.length == 0) {
			validated = false;
			item.firstNameError = "A user requires a first name";
		} else {
			item.firstNameError = "";
		}

		if (item.lastName.length == 0) {
			validated = false;
			item.lastNameError = "A user requires a last name";
		} else {
			item.lastNameError = "";
		}

		if (item.emailAddress.length == 0) {
			item.emailAddressError = "A user requires an email address";
			validated = false;
		} else {
			if (emailRegularExpression.test(item.emailAddress)) {
				item.emailAddressError = "";
			} else {
				validated = false;
				item.emailAddressError = "Email address is not formatted correctly"
			}
		}

		return validated;
	}

	validateItemPropertyContainsText(value, errorProperty, errorDescription) {
		if (value.length == 0) {
			errorProperty = errorDescription;
			return false;
		} else {
			errorProperty = "";
			return true;
		}
	}

	handleValidation() {
		//Need to use the passing back of validated values as setting state values doesn't mean they're updated instantly.
		let validated = true;

		if (!this.validateTextContainsText(this.state.businessName, "businessNameError", "A business requires a name")) {
			validated = false;
		}
		if (!this.validateTextContainsText(this.state.addressLine1, "addressLine1Error", "A business requires a first line of address")) {
			validated = false;
		}
		if (!this.validateTextContainsText(this.state.town, "townError", "A business requires a town")) {
			validated = false;
		}
		if (!this.validateTextContainsText(this.state.postcode, "postcodeError", "A business requires a postcode")) {
			validated = false;
		}
		if (!(this.state.countryId && this.state.countryId > 0)) {
			validated = false;
			this.setState({ ["countryIdError"]: "A business requires a country" });
		} else {
				this.setState({
					["countryIdError"]: ""
				});
		}

		return validated;
	}

	validateTextContainsText(valueToValidate, stateForErrorMessage, errorMessage) {
		if (valueToValidate.length == 0) {
			this.setState({ [stateForErrorMessage]: errorMessage });
			return false;
		} else {
			this.setState({ [stateForErrorMessage]: "" });
			return true;
		}
	}

	async getCountryData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'country');
		const data = await response.json();
		this.setState({ countrys: data.map((country) => new CountryOption(country.countryId, country.countryName)), loading: false });
}

	DisplayCountryDropdown() {
		const { value } = this.state;
		return (<Select
			className={cssFormInputDropdown}
			placeholder='Select Country'
			fluid
			selection
			search
			options={this.state.countrys}
			name="countryId"
			onChange={this.handleDropDownChange}
			value={value}
		/>
		);
	}

	DisplayFranchiseOption() {
		return (<label
			className={cssFormInputLabel}>
			Franchise Business
			<input type={checkBox}
				className={cssFormCheckBox}
				name="franchiseBusiness"
				value={this.state.displayNextSteppedOfferDescription}
				onChange={this.handleInputChange} />
		</label>)
	}

	user(i, item) {
		return <>
			<label className={cssFormInputLabel}>
				User's first name:
				<input type={textField} name="firstName" onChange={this.handleUserChange(i)} className={cssFormInputText} value={item.firstName} maxLength={cognitoAttributeLength} /><br />
			</label>{item.firstNameError && <p className={cssError} >{item.firstNameError}</p>}
			<label className={cssFormInputLabel}>
				User's last name:
				<input type={textField} name="lastName" onChange={this.handleUserChange(i)} className={cssFormInputText} value={item.lastName} maxLength={cognitoAttributeLength} /><br />
			</label>{item.lastNameError && <p className={cssError} >{item.lastNameError}</p>}
			<label className={cssFormInputLabel}>
				Email Address:
				<input type={textField} name="emailAddress" onChange={this.handleUserChange(i)} className={cssFormInputText} value={item.emailAddress} maxLength={emailAddressLength} /><br />
			</label>{item.emailAddressError && <p className={cssError} >{item.emailAddressError}</p>}
			{this.state.franchiseBusiness && <label className={cssFormInputLabel}>Franchisor<input type={checkBox} className={cssFormCheckBox} name="franchisor" onChange={this.handleUserChange(i)} /></label>}
			<div><button type={buttonType} className={cssFormInputButton} onClick={this.handleRemoveUser(i)}>Delete</button></div></>
	}


	render() {
		let countryDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayCountryDropdown();
		let userRole = JSON.parse(sessionStorage.getItem(userRoleHeader))

		let franchiseOption = (userRole == roleAdministrator || userRole == roleAgent) ? this.DisplayFranchiseOption() : '';

		return (<LoyaltyOffersProvider>
			<NavMenu />
			<form onSubmit={this.handleSubmit} className={cssFormInput}>

				{franchiseOption}

				<label className={cssFormInputLabel}>
					Business Name:
					<input type={textField} className={cssFormInputText} name="businessName" value={this.state.businessName} onChange={this.handleInputChange} maxLength={standardTextLength} />
				</label>{this.state.businessNameError && <p className={cssError} >{this.state.businessNameError}</p>}

				<label className={cssFormInputLabel}>
					Address Line 1:
					<input type={textField} className={cssFormInputText} name="addressLine1" value={this.state.addressLine1} onChange={this.handleInputChange} maxLength={standardTextLength} />
				</label>{this.state.addressLine1Error && <p className={cssError} >{this.state.addressLine1Error}</p>}

				<label className={cssFormInputLabel}>
					Address Line 2:
					<input type={textField} className={cssFormInputText} name="addressLine2" value={this.state.addressLine2} onChange={this.handleInputChange} maxLength={standardTextLength} />
				</label>

				<label className={cssFormInputLabel}>
					Town:
					<input type={textField} className={cssFormInputText} name="town" value={this.state.town} onChange={this.handleInputChange} maxLength={standardTextLength} />
				</label>{this.state.townError && <p className={cssError} >{this.state.townError}</p>}

				<label className={cssFormInputLabel}>
					County:
					<input type={textField} className={cssFormInputText} name="county" value={this.state.county} onChange={this.handleInputChange} maxLength={standardTextLength} />
				</label>

				<label className={cssFormInputLabel}>
					Postcode:
					<input type={textField} className={cssFormInputText} name="postcode" value={this.state.postcode} onChange={this.handleInputChange} maxLength={standardTextLength} />
				</label>{this.state.postcodeError && <p className={cssError} >{this.state.postcodeError}</p>}

				<label className={cssFormInputLabel}>
					Country
					{countryDropdown}
				</label>{this.state.countryIdError && <p className={cssError}>{this.state.countryIdError}</p>}

				<label className={cssFormInputLabel}>
					Email Address:
					<input type={textField} className={cssFormInputText} name="emailAddress" value={this.state.emailAddress} onChange={this.handleInputChange} maxLength={emailAddressLength} />
				</label>

				<label className={cssFormInputLabel}>
					Phone Number:
					<input type={textField} className={cssFormInputText} name="phoneNumber" value={this.state.phoneNumber} onChange={this.handleInputChange} maxLength="20" />
				</label>

				<label className={cssFormInputFile}>
					Business Icon:
					<input type={fileFieldType} name="icon" onChange={this.handleFileChange} className={cssFormInputFile} /><br />
				</label>

				<label className={cssFormInputFile}>
					Notification Icon:
					<input type={fileFieldType} name="notificationIcon" onChange={this.handleFileChange} className={cssFormInputFile} /><br />
				</label>

				{this.state.users.map((item, i) => (
					this.user(i, item)
				))}
				<button type={buttonType} className={cssFormInputButton} onClick={this.handleAddUser }>Add User</button>

				<input type="submit" value="Save Business" className={cssFormInputButton} disabled={this.state.sumbitButtonDisabled} />
			</form>
				{ this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : '' }
			<div id="businessSaveResponse" />
		</LoyaltyOffersProvider>
		);
	}
}
