import React, { Component } from 'react'
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext'
import { NavMenu } from './NavMenu'
import DualBallSpinner from '../img/dual-ball-1s-100px.gif'
import { fetchSignedMessage, getMethod, getSessionValue, emailHeader, httpOKsuccess, cssFormInputText, cssFormInput, cssFormInputLabel, cssFormInputButton, redirectReasonHeader, cssError, emailRegularExpression, emailAddressLength } from './Library'
import logo from '../img/app-icon-without-background.png';
import ReactDOM from 'react-dom';
import { error } from 'jquery';
import validator from 'validator';

export class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            emailAddress: '',
            oldPassword: '',
            newPassword: '',
            verifyPassword: '',
            errorEmailAddress: '',
            errorNewPassword: '',
            errorVerifyPassword: ''
        }
    }

    async componentDidMount() {
        if (sessionStorage.getItem(emailHeader) != null) {
            this.setState({ emailAddress: sessionStorage.getItem(emailHeader) });
        }

        if (sessionStorage.getItem(redirectReasonHeader)=="Password needs to be changed") {

            const redirectLabel = document.getElementById("redirectMessage");
            ReactDOM.render(<label className={cssError}>You need to change your password. In the case of a new user, this is to ensure that only you know your password.</label>, redirectLabel
            );
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handlePasswordChange = async (e) => {
        e.preventDefault();
        const isValid = this.handleValidation();
        if (isValid) {
            this.setState({ loading: true });

            const changePasswordResponse = await fetch(process.env.REACT_APP_API_ENDPOINT + 'user/changepassword?username=' + this.state.emailAddress + '&oldpassword=' + encodeURIComponent(this.state.oldPassword) + '&newpassword=' + encodeURIComponent(this.state.newPassword));

            var succesful = (changePasswordResponse.status == httpOKsuccess);
            var messageLabel = document.getElementById("changePasswordMessage");

            this.setState({ loading: false })
            if (succesful) {
                ReactDOM.render(<label className={cssFormInputLabel}>Password succesfully changed. You can now <a href='logon'>logon</a> normally.</label>, messageLabel)
            } else {
                const failureReason = changePasswordResponse.headers.get("Password-Issue");
                this.setState({ errorVerifyPassword: failureReason });
            }
        }
    }

    handleValidation() {
        let validated = true;
        const errors = [];

        if (this.state.emailAddress == "") {
            validated = false;
            errors.push("You must enter your email address");
        } else {
            if (emailRegularExpression.test(this.state.emailAddress)) {
                this.state.emailAddressError = "";
            } else {
                validated = false;
                errors.push("Email address is not formatted correctly");
            }
        }

        if (this.state.oldPassword == "") {
            validated = false;
            errors.push("You must enter your old password");
        }

        if (this.state.newPassword == "") {
            validated = false;
            errors.push("You must enter your new password")
        }

        if (this.state.newPassword != this.state.verifyPassword) {
            validated = false;
            errors.push("Passwords must match");
        }

        if (!validator.isStrongPassword(this.state.newPassword, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })) {
            validated = false;
            errors.push("Password must be eight characters in length, and contain a lowercase, uppercase, number and a symbol");
        }

        this.setState({ errorVerifyPassword: errors.map(error => <li>{error}</li>)})

        return validated;
    }

    render() {
        return (
            <><header>
                <ul className="navbar-nav flex-grow">
                    <img className="logo" src={logo} alt="Receipts and Rewards Logo" width="58px" height="58px" /><div> Receipts  and Rewards </div></ul>
            </header>
                <div id="redirectMessage"/>
                <form onSubmit={this.handlePasswordChange} className={cssFormInput}>
                    <label className={cssFormInputLabel}>
                        Email:
                        <input type="text" className={cssFormInputText} name="emailAddress" value={this.state.emailAddress} onChange={this.handleInputChange} maxLength={emailAddressLength} />
                    </label>{this.state.errorEmailAddress && <p className={cssError}>{this.state.errorEmailAddress}</p>}
                    <label className={cssFormInputLabel}>
                        Old Password:
                        <input type="password" className={cssFormInputText} name="oldPassword" value={this.state.oldPassword} onChange={this.handleInputChange} maxLength={passwordLength} />
                    </label>
                    <label className={cssFormInputLabel}>
                        New Password:
                        <input type="password" className={cssFormInputText} name="newPassword" value={this.state.newPassword} onChange={this.handleInputChange} maxLength={passwordLength} />
                    </label>{this.state.errorNewPassword && <p className={cssError}>{this.state.errorNewPassword}</p>}
                    <label className={cssFormInputLabel}>
                        Verify Password:
                        <input type="password" className={cssFormInputText} name="verifyPassword" value={this.state.verifyPassword} onChange={this.handleInputChange} maxLength={passwordLength} />
                    </label>{this.state.errorVerifyPassword && <p className={cssError}>{this.state.errorVerifyPassword}</p>}
                    <input type="submit" className={cssFormInputButton} value="Change Password" />
                </form>

                {this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : ''}
            <div id="changePasswordMessage" />
        </>
        );
    }
}