import { v4 as uuidv4 } from 'uuid'
import { S3Client, PutObjectCommand, ObjectCannedACL} from "@aws-sdk/client-s3"

const sessionTokenKey = 'X-Amz-Session-Token';
const accessKeyHeader = 'X-Amz-Access-Key';
const secretKeyHeader = 'X-Amx-Secret-Key';
const accessTokenExpiryHeader = 'X-Amz-Session-Expiry';
export const businessIdHeader = 'businessid';
export const businessesHeader = 'businesses';
export const userRoleHeader = 'User-Role';
export const userIdHeader = 'User-Id';
const securityToken = 'X-Amz-Security-Token';
export const httpOKsuccess = '200';
export const httpConflict = '409';
const amzDate = 'X-Amz-Date';
const hostHeader = 'Host';
export const emailHeader = 'EMail';
export const redirectReasonHeader = 'Redirect-Reason';
export const redirectToParam = 'redirectTo';
export const getMethod = 'GET';
export const postMethod = 'POST';
export const putMethod = 'PUT';
export const cssFormInput = 'form-input';
export const cssFormInputLabel = 'form-input-label';
export const cssFormInputText = 'form-input-text';
export const cssFormInputButton = 'form-input-button';
export const cssFormInputFile = 'form-input-file';
export const cssFormCheckBox = 'form-check-box';
export const cssError = 'error';
export const cssFormInputNumber = 'form-input-number';
export const cssFormInputDropdown = 'form-input-dropdown';
export const https = 'https://';
export const s3resource = '.s3.';
export const awsLocation = '.amazonaws.com/';
export const fileFieldType = 'file';
export const textField = 'text';
export const numberField = 'number';
export const timeField = 'time';
export const passwordField = 'password';
export const checkBox = 'checkbox';
export const buttonType = 'button';
export const roleAdministrator = 'Administrators';
export const roleAgent = 'Agents';
export const roleMerchant = 'Merchants';
export const roleFranchisor = 'Franchisors';
export const emailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const notificationTitleLength = 65;
export const notificationBodyLength = 178;
export const standardTextLength = 255;
export const longTextLength = 2000;
export const standardMinusTextLength = 200;
export const emailAddressLength = 320;
export const passwordLength = 99;
export const cognitoAttributeLength = 2048;

export async function isLoggedIn() {
	if (isDevelopmentEnvironment()) {
		sessionStorage.setItem(businessIdHeader, process.env.REACT_APP_TEST_BUSINESS_ID);
		sessionStorage.setItem(accessKeyHeader, process.env.REACT_APP_ACCESS_KEY);
		sessionStorage.setItem(secretKeyHeader, process.env.REACT_APP_SECRET_ACCESS_KEY);
		var now = new Date();
		const numberOfSecondsInADay = 86400000;
		now.setTime(now.getTime() + numberOfSecondsInADay);
		var isoTokenExpiry = now.toISOString().replaceAll("-", "");
		sessionStorage.setItem(accessTokenExpiryHeader, isoTokenExpiry);
	} else {
		if (!((sessionStorage.getItem(sessionTokenKey) && isAWSSessionValid()) || await login())) {
			var currentPage = window.location.protocol + '//' + window.location.host + window.location.pathname;
			window.location.href = '/logon?' + redirectToParam + '=' + encodeURIComponent(currentPage);
		}
	}
}

function isDevelopmentEnvironment() {
	return false; // !process.env.NODE_ENV || process.env.REACT_APP_API_ENDPOINT.includes('localhost') || process.env.REACT_APP_API_ENDPOINT.includes('ngrok');
}

export async function login() {
	var succesful;

	var merchantId = new URLSearchParams(window.location.search).get('merchant_id');
	var employeeId = new URLSearchParams(window.location.search).get('employee_id');
	var clientId = new URLSearchParams(window.location.search).get('client_id');
	var code = new URLSearchParams(window.location.search).get('code');


	if (merchantId && employeeId && clientId && code) {

		const loginResponse = await fetch(process.env.REACT_APP_API_ENDPOINT + 'clover/login?merchant_id=' + merchantId + '&employee_id=' + employeeId + '&client_id=' + clientId + '&code=' + code);

		succesful = (loginResponse.status == httpOKsuccess);

		if (succesful) {
			const headers = await loginResponse.headers;
			writeHeaderToSession(headers, sessionTokenKey);
			writeHeaderToSession(headers, accessKeyHeader);
			writeHeaderToSession(headers, secretKeyHeader);
			writeHeaderToSession(headers, accessTokenExpiryHeader);
			writeHeaderToSession(headers, userRoleHeader);
			writeHeaderToSession(loginResponseHeaders, userIdHeader);

			writeFirstBusinessIdToBusinessIdHeader(headers);
		}

		return succesful;
	}
	return false;
}

export function logout() {
	sessionStorage.clear();
	var currentPage = window.location.protocol + '//' + window.location.host + window.location.pathname;
	window.location.href = '/logon?' + redirectToParam + '=' + encodeURIComponent(currentPage);
}

export function writeLoginTokensToSession(loginResponseHeaders) {
	writeHeaderToSession(loginResponseHeaders, sessionTokenKey);
	writeHeaderToSession(loginResponseHeaders, accessKeyHeader);
	writeHeaderToSession(loginResponseHeaders, secretKeyHeader);
	writeHeaderToSession(loginResponseHeaders, accessTokenExpiryHeader);
	writeHeaderToSession(loginResponseHeaders, userRoleHeader);
	writeHeaderToSession(loginResponseHeaders, userIdHeader);

	writeFirstBusinessIdToBusinessIdHeader(loginResponseHeaders);
}

function writeFirstBusinessIdToBusinessIdHeader(loginResponseHeaders) {
	sessionStorage.setItem(businessIdHeader, JSON.stringify(loginResponseHeaders.get(businessIdHeader).split(',')[0]));
}

export async function merchantHasLoyaltyOffers() {

	var businessId;
	const tenthOfASecond = 100;

	while (businessId == null) {
		businessId = getSessionValue(businessIdHeader);
		if (businessId == null) {
			await sleep(tenthOfASecond);
		}
	}

	const response = await fetchSignedMessage(null, 'business/' + businessId + '/loyaltyoffers', 'GET');
	const data = await response.json();
	return (data.length > 0);
	
}

const sleep = ms => new Promise(
	resolve => setTimeout(resolve, ms)
);

function writeHeaderToSession(headers, key) {
	sessionStorage.setItem(key, JSON.stringify(headers.get(key)));
}

export function getSessionValue(key) {
	const tokenString = sessionStorage.getItem(key);
	return JSON.parse(tokenString);
}

export function isAWSSessionValid() {
	if (isDevelopmentEnvironment()) {
		return true;
	} else {
		var tokenExpiryDate = new Date(getSessionValue(accessTokenExpiryHeader));
		var now = new Date();

		return (tokenExpiryDate > now);
	}
}

export function postAttachment(attachment) {
	//https://stackoverflow.com/questions/74303350/react-how-to-upload-files-to-aws-s3-in-react-using-aws-sdk-client-s3-npm-pack
	if (isAWSSessionValid()) {
		const s3Client = new S3Client({
			apiVersion: '2010-08-01',
			region: process.env.REACT_APP_AWS_REGION,
			credentials: {
				accessKeyId: getSessionValue(accessKeyHeader),
				secretAccessKey: getSessionValue(secretKeyHeader),
				sessionToken: getSessionValue(sessionTokenKey)
			},
		});

		var fileType = attachment.type.split("/")[1];

		var uploadedFileName = uuidv4() + "." + fileType;

		const params = {
			Bucket: process.env.REACT_APP_MESSAGE_BUCKET_NAME,
			Key: uploadedFileName,
			Body: attachment,
			ACL: ObjectCannedACL.public_read
		};

		const command = new PutObjectCommand(params);
		const data = s3Client.send(command);
		console.log(data);

		return uploadedFileName;
	} else {
		isLoggedIn();
	}
}

export function uploadFileToBucket(file, bucket) {
	//https://stackoverflow.com/questions/74303350/react-how-to-upload-files-to-aws-s3-in-react-using-aws-sdk-client-s3-npm-pack
	if (isAWSSessionValid()) {
		const s3Client = new S3Client({
			apiVersion: '2010-08-01',
			region: process.env.REACT_APP_AWS_REGION,
			credentials: {
				accessKeyId: getSessionValue(accessKeyHeader),
				secretAccessKey: getSessionValue(secretKeyHeader),
				sessionToken: getSessionValue(sessionTokenKey)
			},
		});

		var fileType = file.type.split("/")[1];

		var uploadedFileName = uuidv4() + "." + fileType;

		const params = {
			Bucket: bucket,
			Key: uploadedFileName,
			Body: file,
			ACL: ObjectCannedACL.public_read
		};

		const command = new PutObjectCommand(params);
		const data = s3Client.send(command);
		console.log(data);

		return uploadedFileName;
	} else {
		isLoggedIn();
	}
}

export function fetchSignedMessage(messageData, endpoint, method) {

	if (isAWSSessionValid()) {
		const baseUrl = process.env.REACT_APP_API_ENDPOINT;
		const indexOfFirstForwardslashes = baseUrl.indexOf("//") + 2;
		const indexOfSecondForwardSlash = baseUrl.indexOf("/", indexOfFirstForwardslashes);
		const host = baseUrl.substring(indexOfFirstForwardslashes, indexOfSecondForwardSlash);
		const region = process.env.REACT_APP_AWS_REGION;
		const service = 'execute-api';

		if (isDevelopmentEnvironment()) {
			return fetch(baseUrl + endpoint, {
				method: method,
				body: messageData,
				headers: {
					'Content-Type': 'application/json'
				}
			}).then((response => {
				if (response.ok) {
					return response;
				}
				console.log(response.status + ":" + response.statusText + ":" + response.text);
            }));
		} else {

			var today = new Date();
			let awsDate = today.getUTCFullYear().toString() + getTwoDigitTime(today.getUTCMonth() + 1) + getTwoDigitTime(today.getUTCDate()) + 'T' + getTwoDigitTime(today.getUTCHours()) + getTwoDigitTime(today.getUTCMinutes()) + getTwoDigitTime(today.getUTCSeconds()) + 'Z';
			let dateStamp = today.getUTCFullYear().toString() + getTwoDigitTime(today.getUTCMonth() + 1) + getTwoDigitTime(today.getUTCDate());

			var endOfHostWithinBaseUrl = baseUrl.indexOf(host) + host.length;
			var canonicalUrl = baseUrl.substring(endOfHostWithinBaseUrl) + endpoint;

			var canonicalQueryString = '';
			var canonicalHeaders = hostHeader.toLowerCase() + ':' + host + '\n' + amzDate.toLowerCase() + ':' + awsDate + '\n' + securityToken.toLowerCase() + ':' + getSessionValue(sessionTokenKey) + '\n';
			var signedHeaders = `${hostHeader.toLowerCase()};${amzDate.toLowerCase()};${securityToken.toLowerCase()}`;

			let canonicalRequest = method + '\n' + canonicalUrl + '\n' + canonicalQueryString + '\n' + canonicalHeaders + '\n' + signedHeaders + '\n' + hashOfString(messageData);

			let algorithm = 'AWS4-HMAC-SHA256';
			let credentialScope = dateStamp + '/' + region + '/' + service + '/' + 'aws4_request';
			let stringToSign = algorithm + '\n' + awsDate + '\n' + credentialScope + '\n' + hashOfString(canonicalRequest);

			let signingKey = getSignatureKey(getSessionValue(secretKeyHeader), dateStamp, region, service)
			var CryptoJS = require("crypto-js");
			let signature = CryptoJS.HmacSHA256(stringToSign, signingKey).toString(CryptoJS.enc.Hex);

			let authorizationHeader = algorithm + ' ' + 'Credential=' + getSessionValue(accessKeyHeader) + '/' + credentialScope + ', ' + 'SignedHeaders=' + signedHeaders + ', ' + 'Signature=' + signature

			if (method == postMethod) {
				return fetch(baseUrl + endpoint, {
					method: method,
					body: messageData,
					headers: {
						'Authorization': authorizationHeader,
						'X-Amz-Date': awsDate,
						'X-Amz-Security-Token': getSessionValue(sessionTokenKey),
						'Content-Type': 'application/json'
					}
				});
			} else {
				return fetch(baseUrl + endpoint, {
					method: method,
					headers: {
						'Authorization': authorizationHeader,
						'X-Amz-Date': awsDate,
						'X-Amz-Security-Token': getSessionValue(sessionTokenKey)
					}
				});
            }
		}
	} else {
		isLoggedIn();
	}
}


function hashOfString(valueToHash) {
	var CryptoJS = require("crypto-js");
	return CryptoJS.SHA256(valueToHash).toString(CryptoJS.enc.Hex);
}

function getSignatureKey(key, dateStamp, regionName, serviceName) {

	var crypto = require("crypto-js");

	var kDate = crypto.HmacSHA256(dateStamp, "AWS4" + key);
	var kRegion = crypto.HmacSHA256(regionName, kDate);
	var kService = crypto.HmacSHA256(serviceName, kRegion);
	var kSigning = crypto.HmacSHA256("aws4_request", kService);
	return kSigning;
}

function getTwoDigitTime(number) {
	return number.toString().padStart(2, '0');
}
