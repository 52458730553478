import React, { Component } from 'react'
import Select from 'react-select'
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext';
import { NavMenu } from './NavMenu';
import { cssFormInputText, cssFormInputLabel, cssFormInputButton, cssFormInput, cssFormInputFile, fetchSignedMessage, postMethod, httpOKsuccess, getSessionValue, businessIdHeader, postAttachment, https, s3resource, awsLocation, standardMinusTextLength} from './Library';
import DualBallSpinner from '../img/dual-ball-1s-100px.gif';
import ShowHelp from '../img/help-icon.png'
import HideHelp from '../img/help-close-icon.png'
import ReactDOM from 'react-dom'

export class FilterProcedureOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}

export class FilterDimension {
	constructor(dimensionId, dimensionName, filterProcedures) {
		this.dimensionId = dimensionId;
		this.dimensionName = dimensionName;
		this.filterProcedures = filterProcedures;
	}
}



export class Voucher extends Component {
	constructor(props) {
		super(props)
		this.state = {
			businessId: '',
			consumerDeviceId: '',
			businessVoucherNumber: '',
			title: '',
			text: '',
			attachment: null,
			attachmentPath: '',
			validFrom: null,
			expiry: null,
			redeemed: null,
			filterDimensions: [],
			loading: true,
			helpVisible: false,
			submitButtonDisabled: false,
			errorDescription: '',
			errorTitle: '',
			filterProcedureIds: []
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		this.getFilters();

		this.setDateControlToCurrentDateTime('validFrom');
		this.setDateControlToThreeMonthsInFuture('expiry');
	}

	setDateControlToCurrentDateTime(fieldIdentifier) {
		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
		var localISOTimeWithoutSeconds = localISOTime.slice(0, 16);

		var datePicker = document.querySelector('input[name=' + fieldIdentifier + ']');
		datePicker.value = localISOTimeWithoutSeconds;
		this.setState({
			[fieldIdentifier]: localISOTimeWithoutSeconds
		})
	}

	setDateControlToThreeMonthsInFuture(fieldIdentifier) {
		var tzoffset = (new Date()).getTimezoneOffset() * 60000;
		var localOffsetTime = new Date(Date.now() - tzoffset);
		localOffsetTime.setMonth(localOffsetTime.getMonth() + 3);
		var localISOTime = (localOffsetTime).toISOString().slice(0, -1);
		var localISOTimeWithoutSeconds = localISOTime.slice(0, 16);

		var datePicker = document.querySelector('input[name=' + fieldIdentifier + ']');
		datePicker.value = localISOTimeWithoutSeconds;
		this.setState({
			[fieldIdentifier]: localISOTimeWithoutSeconds
		})
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e, data) => {
		const name = data.name;
		if (data.action == 'select-option') {
			if (!this.state.filterProcedureIds.some(fp => fp === data.option.value)) {
				this.setState({ filterProcedureIds: [...this.state.filterProcedureIds, data.option.value] });
			}
		}

		if (data.action == 'clear') {
			this.setState({ filterProcedureIds: this.state.filterProcedureIds.filter(fp => fp !== data.removedValue.value) });
		}

		if (data.action == 'remove-value') {
			this.setState({ filterProcedureIds: this.state.filterProcedureIds.filter(fp => fp !== data.removedValue.value) });
		}
	}

	handleFileChange = (event) => {
		this.setState({
			attachment: event.target.files[0]
		})
	}

	handleSubmit = (event) => {
		event.preventDefault();

		try {
			const isValid = this.handleValidation();

			if (isValid) {
				var responseLabel = document.getElementById("voucherSentResponse");
				this.setState({ loading: true, submitButtonDisabled: true });
				ReactDOM.render("", responseLabel);

				var voucher = {
					businessId: getSessionValue(businessIdHeader),
					businessVoucherNumber: this.state.businessVoucherNumber,
					title: this.state.title,
					text: this.state.text,
					attachment: this.state.attachmentPath,
					validFrom: this.state.validFrom,
					expiry: this.state.expiry,
					redeemed: this.state.redeemed
				}

				if (this.state.attachment != null) {
					this.state.attachmentPath = postAttachment(this.state.attachment);	
					voucher.attachmentPath = https + process.env.REACT_APP_MESSAGE_BUCKET_NAME + s3resource + process.env.REACT_APP_AWS_REGION + awsLocation + this.state.attachmentPath
				}

				var voucherAndUserFilters = {
					userFilterIds: this.state.filterProcedureIds,
					voucher: voucher
				}

				this.sendVoucherAndUpdateReturnMessage(voucherAndUserFilters);
			}
		}
		catch (error) {
			this.setState({ loading: false, submitButtonDisabled: false });
			ReactDOM.render(<label className={cssFormInputLabel}>We didn't even try to send the voucher. Please try again later.</label >, responseLabel);
		}
	}

	async sendVoucherAndUpdateReturnMessage(voucherAndUserFilters) {
		try {
			var responseLabel = document.getElementById("voucherSentResponse");
			const response = await fetchSignedMessage(JSON.stringify(voucherAndUserFilters), 'voucher', postMethod);
			if (response.status == httpOKsuccess) {
				const data = await response.json();
				ReactDOM.render(<label className={cssFormInputLabel}>{data.length} vouchers sent succesfully.</label>, responseLabel);
			} else {
				ReactDOM.render(<label className={cssFormInputLabel}>Voucher sending failed. Please try again later.</label >, responseLabel);
			}
		}
		catch (error) {
			ReactDOM.render(<label className={cssFormInputLabel}>Voucher sending failed. Please try again later.</label >, responseLabel);
		}
		finally {
			this.setState({ loading: false, submitButtonDisabled: false });
		}
		
	}

	handleValidation() {
		let validated = true;

		if (!this.validateTextContainsText(this.state.text, "errorDescription", "A message requires text")) {
			validated = false;
		}

		if (!this.validateTextContainsText(this.state.title, "errorTitle", "A message requires a title")) {
			validated = false;
		}

		return validated;
	}

	validateTextContainsText(valueToValidate, stateForErrorMessage, errorMessage) {
		if (valueToValidate.length == 0) {
			this.setState({ [stateForErrorMessage]: errorMessage });
			return false;
		} else {
			this.setState({ [stateForErrorMessage]: "" });
			return true;
		}
	}

	async getFilters() {
		try {
			const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'userfilterdimension/procedures');
			const data = await response.json();
			this.setState({ filterDimensions: data.map((filterDimension) => new FilterDimension(filterDimension.dimensionId, filterDimension.dimensionName, filterDimension.procedureList.map((procedure) => new FilterProcedureOption(procedure.userFilterProcedureId, procedure.userFilterProcedureId, procedure.description)))), loading: false });
		}
		catch (error) {
			console.log(error);
			const networkErrorEmail = "mailto:tech@receiptsandrewards.com?subject=Login%20-%20Network%20Error&body=A%20User%20is%20unable%20to%20logon%20to%20" + window.location.host;
			var messageLabel = document.getElementById("voucherSentResponse");
			ReactDOM.render(<label className={cssFormInputLabel} >There appears to be a network error. Please <a href={networkErrorEmail}>contact Receipts and Rewards</a> and we will fix it.</label>, messageLabel);
			this.setState({ loading: false, buttonDisabled: false });
		}
	}


	DisplayFiltersDropdowns() {
		const { value } = this.state;
		const rows = [];
		for (var i = 0; i < this.state.filterDimensions.length; i++) {
			var filterDimension = this.state.filterDimensions[i];
			rows.push(<div id={filterDimension.dimensionId}>
				<label className={cssFormInputLabel}> {filterDimension.dimensionName}
					<Select
						className='form-input-dropdown'
						placeholder='Filter...'
						fluid
						selection
						search
						options={filterDimension.filterProcedures}
						name='filterProcedureId'
						onChange={this.handleDropDownChange}
						value={value}
						isMulti={true}
					/>
				</label>
			</div>)
		}

		return <div name='filters'>{rows}</div>
	}

	toggleHelp = (event) => {
		this.setState({
			helpVisible: !this.state.helpVisible
		});
	}

	render() {

		return (
			<LoyaltyOffersProvider>
				<NavMenu />
				<form className={cssFormInput} onSubmit={this.handleSubmit}>

				{this.DisplayFiltersDropdowns()}

					<label className={cssFormInputLabel}>
						Title:
						<input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} className={cssFormInputText} maxLength={standardMinusTextLength} />
					</label>{this.state.errorTitle && <p className="error">{this.state.errorTitle}</p>}

					<label className={cssFormInputLabel}>
						Text:
						<input type="text" name="text" value={this.state.text} onChange={this.handleInputChange} className={cssFormInputText} maxLength={standardMinusTextLength} />
					</label>{this.state.errorDescription && <p className="error">{this.state.errorDescription}</p>}

					<label className={cssFormInputLabel}>
						Voucher Image File:
						<input type="file" name="attachment" onChange={this.handleFileChange} className={cssFormInputFile} />
					</label>

					<label className={cssFormInputLabel}>
						ValidFrom:
						<input type="datetime-local" name="validFrom" value={this.state.validFrom} onChange={this.handleInputChange} className={cssFormInputText} />
					</label>

					<label className={cssFormInputLabel}>
						Expiry:
						<input type="datetime-local" name="expiry" value={this.state.expiry} onChange={this.handleInputChange} className={cssFormInputText} />
					</label>

					<input type="submit" value="Send Vouchers" className={cssFormInputButton} disabled={this.state.submitButtonDisabled} /><img src={this.state.helpVisible ? HideHelp : ShowHelp} className="btn-help" onClick={this.toggleHelp} />
				<div id="voucherSentResponse" />
				</form>
				<p id="help" className={"collapse" + (this.state.helpVisible ? ".in" : "")}>
					Use this form to send vouchers to your customers. You can filter whom you wish to send the voucher to using the dropdowns at the top of the page. Title is a short description of the offer, and the text allows you to add a description. Both fields have a maximum length of 200 characters. </p>
				{ this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : '' }
			</LoyaltyOffersProvider>
		);
	}
}
