import React, { Component } from 'react';
import Select from 'react-select';
import { cssFormInputDropdown, cssFormInput, cssFormInputLabel, cssFormInputText, cssFormInputButton, numberField, postMethod, textField, checkBox, cssFormInputNumber, isLoggedIn, getSessionValue, businessIdHeader, timeField, fetchSignedMessage, httpOKsuccess, cssFormCheckBox, cssError, notificationTitleLength, notificationBodyLength, longTextLength, standardMinusTextLength } from './Library';
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext';
import { NavMenu } from './NavMenu';
import ReactDOM from 'react-dom';
import ShowHelp from '../img/help-icon.png'
import HideHelp from '../img/help-close-icon.png'
import DualBallSpinner from '../img/dual-ball-1s-100px.gif';

const saveResponseDiv = "saveResponse";
export class ReviewTypeOption {
	constructor(key,  text) {
		this.label = text;
		this.value = key;
	}
}




export class ReviewRequestTemplate extends Component {
	constructor(props) {
		super(props)
		this.state = {
			businessId: '',
			internalReview: false,
			alertTitle: '',
			alertBody: '',
			clickThroughLink: '',
			reviewScreenInstructions: '',
			externalReviewRequestInstructions: '',
			thankYouForYourFeedbackMessage: '',
			submitFeedbackButton: '',
			externalReviewYes: '',
			externalReviewNo: '',
			minimumNumberOfVisitsBeforeMessage: null,
			numberOfMinutesBeforeSendingMessage: null,
			earliestTimeForMessage: null,
			latestTimeForMessage: null,
			alertTitleError: '',
			alertBodyError: '',
			clickThroughLinkError: '',
			minimumNumberOfVisitsBeforeMessageError: '',
			reviewScreenInstructionsError: '',
			externalReviewRequestInstructionsError: '',
			thankYouForYourFeedbackMessageError: '',
			submitFeedbackButtonError: '',
			externalReviewYesError: '',
			externalReviewNoError: '',
			loading: true,
			sumbitButtonDisabled: false
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		isLoggedIn();
		this.setState({ loading: false });
	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e, data) => {
		const name = data.name;
		this.setState({
			[name]: e.value
		})
	}

	handleValidation() {
		let validated = true;

		if (!this.validateItemPropertyContainsText(this.state.alertTitle, "alertTitleError", "A review request requires an alert title")) {
			validated = false;
		}

		if (!this.validateItemPropertyContainsText(this.state.alertBody, "alertBodyError", "A review request requires an alert body")) {
			validated = false;
		}

		if (!this.validateItemPropertyContainsText(this.state.clickThroughLink, "clickThroughLinkError", "A review request requires a click through destination")) {
			validated = false;
		}

		if (this.state.minimumNumberOfVisitsBeforeMessage == null || this.state.minimumNumberOfVisitsBeforeMessage <= 0) {
			this.setState({ minimumNumberOfVisitsBeforeMessageError: "You must select the number of visits before sending the message" });
			validated = false;
		} else {
			this.setState({ minimumNumberOfVisitsBeforeMessageError: "" });
		}

		if (this.state.internalReview) {
			if (!this.validateItemPropertyContainsText(this.state.reviewScreenInstructions, "reviewScreenInstructionsError", "For an internal review we require instructions")) {
				validated = false;
			}

			if (!this.validateItemPropertyContainsText(this.state.externalReviewRequestInstructions, "externalReviewRequestInstructionsError", "For an internal review, we require a message to request the external review")) {
				validated = false;
			}

			if (!this.validateItemPropertyContainsText(this.state.thankYouForYourFeedbackMessage, "thankYouForYourFeedbackMessageError", "For an internal review, we require a message to thank the reviewer")) {
				validated = false;
			}

			if (!this.validateItemPropertyContainsText(this.state.submitFeedbackButton, "submitFeedbackButtonError", "For an internal review, we require the text for the submission of the feedback button")) {
				validated = false;
			}

			if (!this.validateItemPropertyContainsText(this.state.externalReviewYes, "externalReviewYesError", "For an internal review, we require a label for the submission of feedback")) {
				validated = false;
			}

			if (!this.validateItemPropertyContainsText(this.state.externalReviewNo, "externalReviewNoError", "For an internal review, we require a label for the button not to submit feedback")) {
				validated = false;
			}
		} else {
			this.setState({ "reviewScreenInstructionsError": "" });
			this.setState({ "externalReviewRequestInstructionsError": "" });
			this.setState({ "thankYouForYourFeedbackMessageError": "" });
			this.setState({ "submitFeedbackButtonError": "" });
			this.setState({ "externalReviewYesError": "" });
			this.setState({ "externalReviewNoError": "" });
		}

		return validated;
	}

	validateItemPropertyContainsText(value, errorProperty, errorDescription) {
		if (value.length == 0) {
			this.setState({
				[errorProperty]: errorDescription
			});
			return false;
		} else {
				this.setState({
					[errorProperty]: ""
				});
			return true;
		}
	}

	handleSubmit = (event) => {

		if (this.handleValidation()) {

			var responseLabel = document.getElementById(saveResponseDiv);

			this.setState({ loading: true, submitButtonDisabled: true });
			ReactDOM.render('', responseLabel);

			const reviewRequestTemplate = {
				businessId: getSessionValue(businessIdHeader),
				internalReview: this.state.internalReview,
				alertTitle: this.state.alertTitle,
				alertBody: this.state.alertBody
			}

			if (this.state.clickThroughLink != null) {
				reviewRequestTemplate.clickThroughLink = this.state.clickThroughLink;
			}

			if (this.state.reviewScreenInstructions != null) {
				reviewRequestTemplate.reviewScreenInstructions = this.state.reviewScreenInstructions;
			}

			if (this.state.externalReviewRequestInstructions != null) {
				reviewRequestTemplate.externalReviewRequestInstructions = this.state.externalReviewRequestInstructions;
			}

			if (this.state.thankYouForYourFeedbackMessage != null) {
				reviewRequestTemplate.thankYouForYourFeedbackMessage = this.state.thankYouForYourFeedbackMessage;
			}

			if (this.state.submitFeedbackButton != null) {
				reviewRequestTemplate.submitFeedbackButton = this.state.submitFeedbackButton;
			}

			if (this.state.externalReviewYes != null) {
				reviewRequestTemplate.externalReviewYes = this.state.externalReviewYes;
			}

			if (this.state.externalReviewNo != null) {
				reviewRequestTemplate.externalReviewNo = this.state.externalReviewNo;
			}

			if (this.state.minimumNumberOfVisitsBeforeMessage != null) {
				reviewRequestTemplate.minimumNumberOfVisitsBeforeMessage = this.state.minimumNumberOfVisitsBeforeMessage;
			}

			if (this.state.numberOfMinutesBeforeSendingMessage != null) {
				reviewRequestTemplate.numberOfMinutesBeforeSendingMessage = this.state.numberOfMinutesBeforeSendingMessage;
			}

			if (this.state.earliestTimeForMessage != null) {
				reviewRequestTemplate.earliestTimeForMessage = this.state.earliestTimeForMessage + ":00";
			}

			if (this.state.latestTimeForMessage != null) {
				reviewRequestTemplate.latestTimeForMessage = this.state.latestTimeForMessage + ":00";
			}


			fetchSignedMessage(JSON.stringify(reviewRequestTemplate), 'reviewrequesttemplate', postMethod).then(response => {
				if (response.status == httpOKsuccess) {
					ReactDOM.render(<label className={cssFormInputLabel}>Review request message saved.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				} else {
					ReactDOM.render(<label className={cssFormInputLabel}>Saving review request message failed. Please try again later.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				}
			}).catch(error => {
				this.setState({ loading: false, sumbitButtonDisabled: false });
				ReactDOM.render(<label className={cssFormInputLabel}>Saving review reqeust message failed. Please try again later.</label>, responseLabel)
			});
		}

		event.preventDefault();
	}

	render() {

		return (
			<LoyaltyOffersProvider>
			<NavMenu/>
				<form className={cssFormInput} onSubmit={this.handleSubmit}>
					<label className={cssFormInputLabel}>
						Alert Title:
						<input type={textField} className={cssFormInputText} name="alertTitle" value={this.state.alertTitle} onChange={this.handleInputChange} maxLength={notificationTitleLength} />
					</label>{this.state.alertTitleError && <p className={cssError} >{this.state.alertTitleError}</p>}

					<label className={cssFormInputLabel}>
						Alert Body:
						<input type={textField} className={cssFormInputText} name="alertBody" value={this.state.alertBody} onChange={this.handleInputChange} maxLength={notificationBodyLength} />
					</label>{this.state.alertBodyError && <p className={cssError} >{this.state.alertBodyError}</p>}

					<label className={cssFormInputLabel}>
						Click Through Link:
						<input type={textField} className={cssFormInputText} name="clickThroughLink" value={this.state.clickThroughLink} onChange={this.handleInputChange} maxLength={longTextLength} />
					</label>{this.state.clickThroughLinkError && <p className={cssError} >{this.state.clickThroughLinkError}</p>}

					<label className={cssFormInputLabel}>
						Minimum Number Of Visits Before Sending Message:
						<input type={numberField} className={cssFormInputNumber} name="minimumNumberOfVisitsBeforeMessage" value={this.state.minimumNumberOfVisitsBeforeMessage} onChange={this.handleInputChange} />
					</label>{this.state.minimumNumberOfVisitsBeforeMessageError && <p className={cssError} >{this.state.minimumNumberOfVisitsBeforeMessageError}</p>}

					<label className={cssFormInputLabel}>
						Internal Review:
						<input type={checkBox} className={cssFormCheckBox} name="internalReview" value={this.state.internalReview} onChange={this.handleInputChange} />
					</label>
					<div className={"collapse" + (this.state.internalReview ? ".in" : "")}>
					<label className={cssFormInputLabel}>
						Review Screen Instructions:
							<input type={textField} className={cssFormInputText} name="reviewScreenInstructions" value={this.state.reviewScreenInstructions} onChange={this.handleInputChange} maxLength={longTextLength} />
						</label>{this.state.reviewScreenInstructionsError && <p className={cssError} >{this.state.reviewScreenInstructionsError}</p>}

						<label className={cssFormInputLabel}>
						External Review Request Instructions:
							<input type={textField} className={cssFormInputText} name="externalReviewRequestInstructions" value={this.state.externalReviewRequestInstructions} onChange={this.handleInputChange} maxLength={longTextLength} />
						</label>{this.state.externalReviewRequestInstructionsError && <p className={cssError} >{this.state.externalReviewRequestInstructionsError}</p>}

						<label className={cssFormInputLabel}>
						Thank You For Your Feedback Message:
							<input type={textField} className={cssFormInputText} name="thankYouForYourFeedbackMessage" value={this.state.thankYouForYourFeedbackMessage} onChange={this.handleInputChange} maxLength={longTextLength} />
						</label>{this.state.thankYouForYourFeedbackMessageError && <p className={cssError} >{this.state.thankYouForYourFeedbackMessageError}</p>}

						<label className={cssFormInputLabel}>
						Submit Feedback Button Label:
							<input type={textField} className={cssFormInputText} name="submitFeedbackButton" value={this.state.submitFeedbackButton} onChange={this.handleInputChange} maxLength={standardMinusTextLength} />
						</label>{this.state.submitFeedbackButtonError && <p className={cssError} >{this.state.submitFeedbackButtonError}</p>}

						<label className={cssFormInputLabel}>
						External Review Button Yes Label:
							<input type={textField} className={cssFormInputText} name="externalReviewYes" value={this.state.externalReviewYes} onChange={this.handleInputChange} maxLength={standardMinusTextLength} />
						</label>{this.state.externalReviewYesError && <p className={cssError} >{this.state.externalReviewYesError}</p>}

						<label className={cssFormInputLabel}>
						External Review No Button Label:
							<input type={textField} className={cssFormInputText} name="externalReviewNo" value={this.state.externalReviewNo} onChange={this.handleInputChange} maxLength={standardMinusTextLength} />
						</label>{this.state.externalReviewNoError && <p className={cssError} >{this.state.externalReviewNoError}</p>}
					</div>

					<label className={cssFormInputLabel}>
						Number Of Minutes Before Sending Message:
						<input type={numberField} className={cssFormInputNumber} name="numberOfMinutesBeforeSendingMessage" value={this.state.numberOfMinutesBeforeSendingMessage} onChange={this.handleInputChange} />
					</label>

					<label className={cssFormInputLabel}>
						Earliest Time For Message:
						<input type={timeField} className={cssFormInputNumber} name="earliestTimeForMessage" value={this.state.earliestTimeForMessage} onChange={this.handleInputChange} />
					</label>

					<label className={cssFormInputLabel}>
						Latest Time For Message:
						<input type={timeField} className={cssFormInputNumber} name="latestTimeForMessage" value={this.state.latestTimeForMessage} onChange={this.handleInputChange} />
					</label>

					<input type="submit" className={cssFormInputButton} value="Save" />
					<img src={this.state.helpVisible ? HideHelp : ShowHelp} className="btn-help" onClick={this.toggleHelp} />
				</form>
				<p id="help" className={"collapse" + (this.state.helpVisible ? ".in" : "")}>
					Use this form to set up automated requests for reviews. Set up the title and body of alerts that will be recieved by your customers, as well as </p>
				{ this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : '' }
		<div id={saveResponseDiv} />
			</LoyaltyOffersProvider>
		);
	}
}
